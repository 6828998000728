
.projectCardContainer {

  &__cardBody {
    @media screen and (max-width: 767px) {
      box-shadow: 0 4px 22px 0 #00000021;
      padding: 10px 10px 20px;
      margin-top: -40px;
    }
  }

  &__bgImage {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  }
}
